@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Sinhala:wght@100..900&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* primary: "#227AFF",
      light: "#EFF5FF",
      accent: "#A06D3A", */
  @apply bg-cmathsPrimary;
}
body {
  margin: 0;
  font-family: "Poppins", "Noto Serif Sinhala" ,  sans-serif !important ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-family: "Outfit", sans-serif !important; */
  width: 100%;
  overflow-x: hidden;
}
* {
  outline: none !important;
  font-family: "Poppins", "Noto Serif Sinhala" ,  sans-serif !important ;
}
.rounded-font {
  font-family: "Outfit", sans-serif !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
center {
  @apply flex items-center justify-center;
}
.animate {
  @apply transform transition duration-300 ease-in-out;
}
button {
  @apply py-1;
}

.bg-1 {
  background-image: url("./assets/bg1.png");
  background-repeat: no-repeat;
  @apply bg-primary;
  background-position: center;
  background-size: cover;
}
/* #TYPO */
h1 {
  @apply text-4xl lg:text-5xl font-medium;
}
h2 {
  @apply text-3xl font-semibold;
}
h4 {
  @apply text-xl font-semibold;
}
h6 {
  @apply text-base font-semibold;
}
p {
  @apply text-base font-normal text-gray-600;
}

input {
  @apply bg-white  placeholder:text-gray-300 !important;
}
.ff-email-widget {
  @apply bg-gray-100 !important;
}
.ff-powered-img img {
  @apply hidden !important;
}
.switch-check input {
  background-color: #227aff !important;
}
.switch-uncheck input {
  background-color: #7c7c7c !important;
}

/****** Combined Maths Home ******/

main:not(.cmaths-main) {
  @apply container mx-auto px-4 lg:px-8 py-4 overflow-x-hidden;
}

section.cmaths {
  @apply relative py-10 lg:py-[60px];
}

.context {
  @apply px-4 md:px-6 lg:px-8 xl:px-28 2xl:px-44 mx-auto;
}

.sm {
  @apply xl:px-44 2xl:px-64 mx-auto;
}

/* Buttons */
.btn {
  @apply relative inline-flex items-center justify-center gap-x-2 border border-transparent transition-all duration-300 ease-in-out z-10;
}

.btn-sm {
  @apply text-xs md:text-sm  font-semibold rounded-[25px] px-4 py-1.5  border-2;
}

.btn-md {
  @apply text-sm md:text-base  font-semibold rounded-full px-4 py-1.5 lg:px-7  lg:py-2 border-2;
}

.btn-lg {
  @apply text-sm lg:text-base  font-semibold rounded-full px-4 py-2 lg:px-7  lg:py-3 border-2;
}

.btn-gradient-lg {
  @apply text-sm lg:text-base  font-semibold rounded-full px-5 py-2.5 lg:px-6 lg:py-3.5 border-none;
}

.btn-gradient-md {
  @apply text-sm lg:text-base  font-semibold rounded-full px-4 py-2 lg:px-7  lg:py-2.5 border-none;
}

.btn-white {
  @apply text-cmathsPrimary bg-white border-white hover:bg-transparent hover:border-white hover:text-white;
}

.btn-white-blue {
  @apply text-cmathsPrimary bg-white border-white hover:bg-cmathsPrimary hover:border-cmathsPrimary hover:text-white;
}

.btn-green {
  @apply text-white bg-cmathsAccent border-cmathsAccent hover:bg-transparent hover:border-cmathsAccent hover:text-cmathsAccent;
}

.btn-blue {
  @apply text-white bg-cmathsPrimary border-cmathsPrimary hover:bg-transparent hover:border-cmathsPrimary hover:text-cmathsPrimary;
}

.btn-white-outline {
  @apply text-white bg-transparent border-white hover:bg-white hover:text-cmathsPrimary hover:border-white;
}

.btn-lightBlue {
  @apply text-cmathsPrimary bg-light border-cmathsPrimary hover:bg-cmathsPrimary hover:border-cmathsPrimary hover:text-white;
}
.btn-primary-gradient {
  @apply 
  bg-primary-gradient text-white hover:bg-right-bottom 
  [background-size:200%_auto] transition-all duration-300 ease-in-out;
}

/* Mobile Menu */

.mobile-menu-links {
  @apply flex-initial;
}

.mobile-menu-links ul li a {
  @apply font-medium;
}

/* Combined Maths  Navigation */

.cmaths-logo {
  @apply relative w-40;
}

.cmaths-nav-links ul li a {
  @apply font-bold text-[13px]  rounded-3xl py-3 px-6 transition-all duration-300 ease-in-out uppercase backdrop-blur-md;
}

/* Combined Hero */

.cmaths-hero {
  @apply relative flex flex-col items-center justify-center  bg-no-repeat bg-cover w-full min-h-screen bg-cmathsDark bg-center;
}

/* Combined Hero */

.heading-container {
  @apply w-full md:w-9/12 lg:w-6/12 mx-auto;
}

.cmaths-heading {
  @apply relative mb-5 md:mb-6 lg:mb-12;
}

.cmaths-heading.center {
  @apply text-center;
}

.cmaths-heading h2 {
  @apply text-cmathsDark font-bold text-xl md:text-xl lg:text-2xl xl:text-3xl mb-10  xl:leading-[3rem];
}

.cmaths-heading h2::after {
  @apply content-[''] absolute block bg-primary-gradient h-[3px] md:h-[5px] w-[120px] rounded-lg -bottom-[18px] left-0;
}

.cmaths-heading.center h2::after {
  left: 50%;
  transform: translateX(-50%);
}

.cmaths-heading.dark h2::after {
  @apply bg-none bg-white;
}

.cmaths-heading h2 span {
  @apply bg-primary-gradient bg-clip-text text-transparent;
}

.cmaths-heading p {
  @apply text-sm md:text-base text-gray-500 leading-5 md:!leading-6;
}

.cmaths-heading.dark h2 {
  @apply text-white;
}

.cmaths-heading.dark p {
  @apply text-gray-100;
}

/* Combined Explanation */

.explanation-list {
  @apply relative;
}

.explanation-list ul {
  @apply flex flex-col gap-y-3 md:gap-y-4;
}

.explanation-list ul li {
  @apply flex flex-row gap-x-3 md:gap-x-4;
}

.explanation-list ul li img {
  @apply w-4 h-4;
}

.explanation-list ul li span {
  @apply text-sm md:text-sm font-bold text-gray-900;
}

/* Our Step */

.cmaths-our-steps {
  @apply bg-cmathsPrimary;
}

.cmaths-embedded-item {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  border-radius: 10px;
}

.cmaths-embedded-item iframe,
.cmaths-embedded-item object,
.cmaths-embedded-item embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Result Release */

/* Telegram Channel */

.cmaths-telegram {
  @apply relative;
}

.telegram-box {
  @apply flex flex-col gap-y-4  items-center justify-center  border border-gray-100 rounded-lg py-5 px-4;
}

.telegram-box.dark {
  @apply bg-cmathsDark;
}

.telegram-box img {
  @apply max-w-[40px] md:max-w-[50px];
}

.telegram-box h4 {
  @apply text-cmathsDark font-bold text-[15px]  md:text-lg text-center mb-1 md:mb-2;
}

.telegram-box p {
  @apply text-gray-500 text-xs md:text-[13px] font-medium  leading-5 text-center mb-4;
}

.telegram-box.dark h4 {
  @apply text-white;
}

.telegram-box.dark p {
  @apply text-gray-400;
}

/* Youtube */

.cmaths-video-thumb {
  @apply relative overflow-hidden rounded-lg  transition-all duration-200 ease-in-out;
}

/* Article */

.cmaths-articles {
  @apply bg-[#EFF2F7];
}

.cmaths-article {
  @apply relative w-full lg:w-8/12 mx-auto text-center;
}

.cmaths-article-img {
  @apply relative overflow-hidden rounded-lg mb-4;
}

.cmaths-article-content a {
  @apply  text-cmathsDark font-extrabold text-xl lg:text-2xl mb-2;
}

.cmaths-article-content p {
  @apply  text-gray-500 text-sm lg:text-base;
}

/* About */

.cmaths-about {
  @apply bg-cmathsPrimary;
}

.cmaths-about-content {
  @apply relative space-y-2 lg:space-y-4;
}
.cmaths-about-content p {
  @apply  text-gray-200 text-xs md:text-sm;
}

/* Special Tasks */

.special-tasks {
  @apply relative bg-cmathsPrimary;
}

.special-task-item {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  @apply bg-white relative flex justify-center items-center w-full h-16 sm:h-20 sm:w-40 lg:w-60 md:h-40 rounded-xl px-2 sm:px-4 py-4;
}

.special-task-item img {
  @apply w-20 md:w-36 xl:w-48;
}

/* Footer */

.ft-item {
  @apply relative flex flex-col  space-y-2;
}

.ft-title {
  @apply relative mb-3;
}

.ft-title h6 {
  @apply text-white  text-base font-medium;
}

.ft-info {
  @apply relative;
}

.ft-info ul {
  @apply flex flex-col space-y-3;
}

.ft-info ul li a img,
.ft-info ul li p img {
  @apply w-4;
}

.ft-info ul li a {
  @apply flex  gap-x-4 items-center text-sm text-gray-400 font-medium hover:text-cmathsPrimary transition-all duration-300 ease-in-out;
}

.ft-info ul li p {
  @apply flex  gap-x-4 items-center text-sm text-gray-400 font-medium;
}

.ft-info ul li p {
  @apply text-sm text-gray-400 font-medium;
}

/* Profile */

.courses-details-item {
  @apply relative flex xl:w-full justify-between items-center py-2 px-2  md:py-4 md:px-3  border-[#D4D4D4];
}

.courses-details-item:not(:first-child) {
  @apply xl:border-t border-[#D4D4D4];
}

.courses-details-item span {
  @apply font-semibold md:font-medium text-[13px] md:text-base xl:text-lg text-[#222326];
}

.profile-tabs {
  @apply relative px-0 sm:px-2 md:px-10 py-3  md:py-6 lg:py-8 mx-auto mb-4;
}

.profile-tabs ul li a {
  @apply inline-flex items-center gap-x-2 bg-[#F7F7F7] text-[#6B6B6B] py-2 px-4 rounded-lg font-semibold text-sm md:text-base;
}

.profile-tabs ul li a.active {
  @apply text-cmathsPrimary bg-[#F5F8FE];
}

.profile-content-inner {
  @apply relative;
}

.profile-course-item {
  @apply relative border border-[#D1D1D1] px-6 py-5 rounded-xl;
}

/* Content */

.cmaths-content {
  @apply relative  mx-auto px-4;
}

.cmaths-content-inner {
  @apply relative space-y-2;
}

.cmaths-content-inner p {
  @apply text-gray-500 text-sm md:text-base;
}

.cmaths-content-inner h3 {
  @apply text-cmathsDark font-bold !mt-5 text-[15px] md:text-[17px];
}

.cmaths-content-inner ul li,
.cmaths-content-inner ol li {
  @apply text-gray-500  text-sm md:text-base;
}

/* Tutor */

.tutor-description {
  @apply relative;
}

.tutor-description p {
  @apply text-white;
}

.cb-live-icon {
  position: absolute;
  width: 12px;
  height: 12px;
}

.cb-live-icon:before {
  content: "";
  position: relative;
  display: block;
  width: 250%;
  height: 250%;
  top: -50%;
  box-sizing: border-box;
  margin-left: -75%;
  margin-top: -75%;
  border-radius: 45px;
  background-color: #ff0000;
  animation: custompulse 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

.cb-live-icon:after {
  content: "";
  position: absolute;
  left: 0;
  top: -50%;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #ff0000;
  border-radius: 50px;
  animation: cb-live-icon 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
}

@keyframes custompulse {
  0% {
    transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}

@keyframes cb-live-icon {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}

/* Models */

.webinar-model {
  animation: webinar 0.3s ease forwards;
  @apply relative opacity-0 min-w-[310px] sm:max-w-[400px] py-4 px-3 md:pt-8 pb-5 md:px-4 mx-auto  bg-white rounded-xl;
}

@keyframes webinar {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Mobile Bottom Nav */

.bottom-nav-link.fill > svg {
  @apply fill-gray-400;
}

/* Bank Payment */

.bank-payment-info {
  @apply relative py-2 px-2.5 md:px-4 bg-gray-100 rounded-lg mb-3;
}

.bank-payment-info ul {
  @apply space-y-2;
}

.bank-payment-info ul li {
  @apply font-semibold text-xs sm:text-sm flex  gap-x-2 md:gap-x-3;
}

.bank-payment-info ul li span.bank-payment-title {
  @apply flex-initial flex-shrink-0 w-1/2;
}

/* Checkouts */

.ck-inputs {
  @apply text-base disabled:!border disabled:!border-gray-200 disabled:pointer-events-none;
}

/* Notice Board */

.notice-board-content {
  @apply relative;
}

.notice-board-content h4 {
  @apply text-cmathsDark text-base font-medium mb-4;
}

.notice-board-content ul {
  @apply flex flex-col  space-y-3;
}

.notice-board-content ul li {
  @apply inline-flex justify-start gap-x-2 items-center  text-gray-600;
}

.notice-board-content ul li span {
  @apply text-gray-600 text-sm font-medium;
}

/* Auth Model */

@keyframes reminderAuth {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.auth-model-inner {
  animation: reminderAuth 0.3s ease forwards;
  @apply relative opacity-0 max-w-[310px] sm:max-w-[400px] py-4 px-3 md:py-5 md:px-4 mx-auto  bg-light rounded-xl;
}
